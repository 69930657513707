import React, { Fragment, useState } from 'react'
import styles from './style.module.scss'

import Play from '../../images/youtube-play.svg'

/**
 * <iframe width="560" height="315" src="https://www.youtube.com/embed/IM_XGXIqkWE" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
 *
 */

export default ({ iframeText, title }) => {
    const id = iframeText.match(/embed\/(.*?)"/)[1]
    const [playing, setPlaying] = useState(false)
    return (
        <div className={styles.lightYoutube} onClick={() => setPlaying(true)}>
            {playing ? (
                <iframe
                    src={`https://www.youtube.com/embed/${id}?autoplay=1`}
                    title={title}
                />
            ) : (
                <Fragment>
                    <div className={styles.gradient} />
                    <div
                        className={styles.title}
                        dangerouslySetInnerHTML={{ __html: title }}
                    />
                    <div className={styles.play}>
                        <Play />
                    </div>
                    <img
                        src={`https://i.ytimg.com/vi/${id}/maxresdefault.jpg`}
                        alt={title}
                    />
                </Fragment>
            )}
        </div>
    )
}
